import React from 'react';
import { syncPages, syncPage, syncPageMetafields, savePages, createPageCustomContents, deletePageCustomContent, exportPages, importPages, clearExportUrls, resetErrors } from '../../modules/pages'
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ItemsList from '../../components/ItemsList'
import ItemsListSkeleton from '../../components/ItemsListSkeleton'
import LoadingIndicator from '../../components/LoadingIndicator';
import EmptyView from '../../components/EmptyView';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import OptionsMenu from '../../components/OptionsMenu';
import NewFlexItemList from '../../components/NewFlexItemList';
import cloneDeep from 'lodash/cloneDeep';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import ExportImportDialogs from '../../components/ExportImportDialogs';
import { _clearUnchangedFields } from '../../helper';
import API from '../../api/api';

const styles = theme => ({

});

class Pages extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      addingNewCustomContent: false,
      addingNewCustomContentPageId: null,
      customContentPlainTextData: [],
      showDownloadDialog: true,
      showImportCompleteDialog: false,
      errorLockCollapse: false,
      forceOpenExportDialog: false,
      forceOpenImportDialog: false,
    }
  }

  componentDidMount() {
    this.props.syncPages(this.props.editLanguage)
    if(this.props.pages.export_po_download_url || this.props.pages.export_csv_download_url) {
      this.setState({showDownloadDialog: false})
    }
  }

  componentWillUnmount() {
    API.clearAllFetches();
  }

  getErrorsFor(key, item) {
    if (typeof item.errors === 'undefined') return false;
    let error = item.errors.find(x => x.object_key === key);
    return error;
  }

  componentWillReceiveProps(newProps) {
    const { t } = this.props;
    const currentLanguage = this.props.editLanguage;
    const newLanguage = newProps.editLanguage;
    if(currentLanguage != newLanguage) {
      this.props.syncPages(newLanguage)
      return
    }

    if(this.props.pages.import_pages_pending === true && newProps.pages.import_pages_pending === false) {
      this.setState({showImportCompleteDialog: true}, () => {
        this.setState({showImportCompleteDialog: false});
      })
    }

    if((this.props.pages.sync_page_pending === true && newProps.pages.sync_page_pending === false) || (this.props.pages.save_pages_pending === true && newProps.pages.save_pages_pending === false) || (this.props.pages.sync_pages_pending === true && newProps.pages.sync_pages_pending === false)) {
      const newData = [];
      
      newProps.pages.pages.forEach(item => {
        let customFields = []

        if(item.custom_contents) {
          item.custom_contents.forEach(custom_content => {
            customFields.push({
              id: custom_content.id,
              type: 'text',
              deletable: true,
              multiline: true,
              rows: 7,
              source: custom_content.source.text,
              target: custom_content.target.text
            })
          })
        }

        let currentMetafields = null;
        if(!(this.props.pages.sync_pages_pending === true && newProps.pages.sync_pages_pending === false)) {
          const currentPage = this.state.data.find(x => x.id == item.id);
          if(currentPage) {
            currentMetafields = currentPage.fields.find(x => x.id === 'metafields');
          }
        }

        let seoFields = []

        if(typeof item.target.page_title !== 'undefined') {
          seoFields.push({
            id: 'page_title',
            type: 'text',
            label: 'SEO Title',
            source: item.source.page_title || '',
            target: item.target.page_title || '',
            customized: item.customSeo.meta_title,
            maxLength: 70,
          })
        }

        if(typeof item.target.page_description !== 'undefined') {
          seoFields.push({
            multiline: true,
            rows: 6,
            id: 'page_description',
            type: 'text',
            label: 'SEO Description',
            source: item.source.page_description || '',
            target: item.target.page_description || '',
            customized: item.customSeo.meta_description,
            maxLength: 320,
          })
        }

        newData.push({
          type: 'page',
          id: item.id,
          progress: item.progress,
          shopify_id: item.shopifyId,
          synchronized: item.synchronized,
          metafields_synchronized: item.metafields_synchronized,
          title: item.source.title,
          info: '',
          fields: [{
            id: 'title',
            type: 'text',
            label: 'Title',
            source: item.source.title,
            target: item.target.title || ''
          },
          {
            multiline: true,
            rows: 10,
            id: 'body_html',
            type: 'richtext',
            label: 'Description',
            source: item.source.body_html || '',
            target: item.target.body_html || '',
          },
          {
            id: 'handle',
            type: 'text',
            label: 'Handle',
            source: item.source.handle,
            target: item.target.handle || '',
            errors: this.getErrorsFor('handle', item) || false
          },
          {
            id: 'seofields',
            type: 'subfields',
            fields: seoFields
          },
          {
            id: 'metafields',
            type: 'subfields',
            fields: currentMetafields ? cloneDeep(currentMetafields.fields) : []
          },
          {
            id: 'customfields',
            onAddItem: this.openNewCustomContentDialog,
            onDeleteItem: this.onDeleteCustomContent(item.id),
            emptyMessage: <EmptyView 
              title={t('customContents.noCustomContentInSection')}
              description={t('customContents.info1')}
              bottom={<Button className={this.props.classes.empty_button} variant="outlined" size="small" onClick={e => {this.openNewCustomContentDialog(item.id)}}>{t('customContents.addNewCustomContent')}</Button>}
              dense={true} 
            />,
            type: 'subfields',
            fields: customFields
          }]
        })
      })

      this.setState({data:newData});
    }

    if(this.props.pages.sync_page_metafields_pending === true && newProps.pages.sync_page_metafields_pending === false) {
      let newData = cloneDeep(this.state.data);
      newProps.pages.pages.forEach(page => {
        if(page.metafields) {
            let pageToUpdate = newData.find(x => x.id == page.id)

            let newMetafields = []
            page.metafields.forEach(metafield => {
              const currentField = pageToUpdate.fields.find(x => x.id === 'metafields').fields.find(x => x.id == metafield.id);
              if(!currentField) {
                newMetafields.push({
                  id: metafield.id,
                  type: 'text',
                  multiline: true,
                  rows: 7,
                  source: metafield.source,
                  target: metafield.target || '',
                  label: metafield.namespace + ' -> ' + metafield.key
                })
              } else {
                if(page.metafields.find(x => x.id === currentField.id)) {
                  newMetafields.push({...currentField});
                }
              }
            })

            pageToUpdate.metafields_synchronized = page.metafields_synchronized
            pageToUpdate.fields.find(x => x.id === 'metafields').fields = newMetafields
        }
      })

      this.setState({data:newData});
    }

    if((this.props.pages.create_page_custom_contents_pending === true && newProps.pages.create_page_custom_contents_pending === false) || (this.props.pages.delete_page_custom_content_pending === true && newProps.pages.delete_page_custom_content_pending === false)) {
      let newData = cloneDeep(this.state.data);
      newProps.pages.pages.forEach(page => {
        if(page.custom_contents) {
            let pageToUpdate = newData.find(x => x.id == page.id)

            let newCustomContents = []
            page.custom_contents.forEach(custom_content => {
              const currentField = pageToUpdate.fields.find(x => x.id === 'customfields').fields.find(x => x.id == custom_content.id);
              if(!currentField) {
                newCustomContents.push({
                  id: custom_content.id,
                  type: 'text',
                  deletable: true,
                  multiline: true,
                  rows: 7,
                  source: custom_content.source.text,
                  target: custom_content.target.text
                })
              } else {
                if(page.custom_contents.find(x => x.id === currentField.id)) {
                  newCustomContents.push({...currentField});
                }
              }
            })

            pageToUpdate.custom_contents_synchronized = page.custom_contents_synchronized
            pageToUpdate.fields.find(x => x.id === 'customfields').fields = newCustomContents
        }
      })

      this.setState({data:newData});
    }
  }

  onExpand(id) {
    this.currentPageId = id;
    if(this.state.data.find(x => x.id == id).synchronized !== true) {
      this.props.syncPage(id, this.props.editLanguage);
    }
  }

  onCollapsed = (id) => {
    
  }

  onExpandCollapsableFields = (type, id) => {
    if(type === 'metafields' && this.state.data.find(x => x.id == id).metafields_synchronized != true) {
      this.props.syncPageMetafields(id, this.props.editLanguage)
    }
  }

  onSave = items => {
    const usedItems = [];
    items.forEach(item => {
      usedItems.push({
        id: item.id,
        title: item.fields.find(x => x.id === 'title').target,
        body_html: item.fields.find(x => x.id === 'body_html').target,
        handle: item.fields.find(x => x.id === 'handle').source == item.fields.find(x => x.id === 'handle').target ? '' : item.fields.find(x => x.id === 'handle').target,
        page_title: item.fields.find(x => x.id === 'seofields').fields.find(x => x.id === 'page_title').target,
        page_description: item.fields.find(x => x.id === 'seofields').fields.find(x => x.id === 'page_description').target,
        metafields: item.fields.filter(x => (x.type === 'subfields' && x.id === 'metafields'))[0].fields.map(z => ({ id: z.id, target: z.target })),
        custom_contents: item.fields.filter(x => (x.type === 'subfields' && x.id === 'customfields'))[0].fields.map(z => ({ id: z.id, target: z.target })),
      });
    })

    this.props.savePages(this.props.editLanguage, usedItems)
  }

  onResaveAll = (itemId) => {
    const item = this.state.data.find(x => x.id == itemId);
    if(item) {
      const usedItems = [{
        id: item.id,
        title: item.fields.find(x => x.id === 'title').target,
        body_html: item.fields.find(x => x.id === 'body_html').target,
        page_title: item.fields.find(x => x.id === 'seofields').fields.find(x => x.id === 'page_title').target,
        page_description: item.fields.find(x => x.id === 'seofields').fields.find(x => x.id === 'page_description').target,
        metafields: item.fields.filter(x => (x.type === 'subfields' && x.id === 'metafields'))[0].fields.map(z => ({ id: z.id, target: z.target })),
        custom_contents: item.fields.filter(x => (x.type === 'subfields' && x.id === 'customfields'))[0].fields.map(z => ({ id: z.id, target: z.target })),
      }]

      this.props.savePages(this.props.editLanguage, usedItems, 1)
    }
  }

  openNewCustomContentDialog = (itemId) => {
    const customContentPlainTextData = [];
    const page = this.state.data.find(x => x.id == itemId);

    page.fields.find(x => x.id == 'customfields').fields.forEach((custom_content) => {
      customContentPlainTextData.push(custom_content.source);
    })

    this.setState({addingNewCustomContent: true, addingNewCustomContentPageId: itemId, customContentPlainTextData: customContentPlainTextData})
  }

  closeNewCustomContentDialog = ()  => {
    this.setState({addingNewCustomContent: false, addingNewCustomContentPageId: null, customContentPlainTextData: []})
  }

  onSaveNewCustomContentDialog = (pageId, items) => {
    const usedItems = [];
    items.forEach(item => {
      if(item.text != '') {
        usedItems.push({
          text: item.text
        })
      }
    })
    
    this.setState({addingNewCustomContent: false, addingNewCustomContentPageId: null});
    this.props.createPageCustomContents(pageId, this.props.editLanguage, usedItems);
  }

  onDeleteCustomContent = pageId => {
    return itemId => {
      this.props.deletePageCustomContent(pageId, itemId);
    }
  }

  onImport = (e) => {
    this.setState({forceOpenImportDialog: true}, () => {
      this.setState({forceOpenImportDialog: false});
    });   
    this.setState({ optionsMenuAnchorEl: null });
  }

  onExport = (e) => {
    this.setState({forceOpenExportDialog: true}, () => {
      this.setState({forceOpenExportDialog: false});
    });
  }

  onCloseDownloadDialog = () => {
    this.setState({showDownloadDialog: false})
  }

  onCloseImportCompleteDialog = () => {
    window.location.reload();
  }

  render() {
    const { t, classes } = this.props

    if(this.props.pages.sync_pages_pending === true || (this.props.pages.error && this.props.pages.error.level === 0)) {
      return <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          {t('pages.pages.title')}
        </Typography>
        <Divider className="divider-white" />
        <ItemsListSkeleton rows="10"/>
        <LoadingIndicator
          pending={this.props.pages.sync_pages_pending || this.props.pages.sync_page_pending || this.props.pages.save_pages_pending || this.props.pages.export_pages_pending || this.props.pages.import_pages_pending}
          progress={this.props.pages.progress}
          error={this.props.pages.error}
          withLogo
        />
      </React.Fragment>  
    }

    return <React.Fragment>
      <Typography variant="h4" gutterBottom component="h2">
        {t('pages.pages.title')}
        <OptionsMenu
          onImport={this.onImport}
          onExport={this.onExport}
          shopPlan={this.props.shopPlan}
        />         
      </Typography>

      {this.state.addingNewCustomContent ? (
        <NewFlexItemList
          scope={this.state.addingNewCustomContentPageId}
          data={this.state.customContentPlainTextData}
          onSave={this.onSaveNewCustomContentDialog}
          onCancel={this.closeNewCustomContentDialog}
          isPending={false}
        />
      ) : (
        null
      )}

      <Divider className="divider-white" />

      <ItemsList 
        type="pages"
        pagination={true}
        onExpand={(id) => {this.onExpand(id)}}
        onCollapsed={this.onCollapsed}
        isPending={
          this.props.pages.sync_pages_pending ||
          this.props.pages.sync_page_pending ||
          this.props.pages.sync_page_metafields_pending ||
          this.props.pages.create_page_custom_contents_pending ||
          this.props.pages.delete_page_custom_content_pending ||
          this.props.pages.save_pages_pending ||
          this.props.pages.export_pages_pending ||
          this.props.pages.import_pages_pending
        }
        isSubfieldsPending={
          this.props.pages.create_page_custom_contents_pending ||
          this.props.pages.delete_page_custom_content_pending ||
          this.props.pages.sync_page_metafields_pending
        }
        isSubfieldsRemoving={
          this.props.pages.delete_page_custom_content_pending
        }
        isSaving={
          this.props.pages.save_pages_pending
        }
        onSave={this.onSave}
        onResaveAll={this.onResaveAll}
        data={this.state.data}
        onExpandCollapsableFields={this.onExpandCollapsableFields}
        allowAutoTranslation={true}
        error={this.props.pages.error}
        errorLockCollapse={this.state.errorLockCollapse}
        emptyMessage={
          <EmptyView 
            title={t('pages.pages.noContent')}
            bottom={<Button href={`https://${this.props.shopUrl}/admin/pages`} target="_blank" variant="contained" color="secondary">{t('system.createOneHere')}</Button>}
          />
        }
      />
      <LoadingIndicator
        pending={this.props.pages.sync_pages_pending || this.props.pages.sync_page_pending || this.props.pages.save_pages_pending || this.props.pages.create_page_custom_contents_pending || this.props.pages.delete_page_custom_content_pending || this.props.pages.sync_page_metafields_pending || this.props.pages.export_pages_pending || this.props.pages.import_pages_pending}
        loadingType={this.props.pages.save_pages_pending ? 'saving' : 'loading'}
        progress={this.props.pages.progress}
        error={this.props.pages.error}
        onErrorCancel={this.props.resetErrors}
        onErrorRetry={() => {
          this.setState({ errorLockCollapse: true }, () => {
            this.setState({ errorLockCollapse: false });
          });
        }}
        withLogo
      />

      <ExportImportDialogs
        //showDownloadDialog={this.state.showDownloadDialog}
        showImportCompleteDialog={this.state.showImportCompleteDialog}
        imported_entries={this.props.pages.imported_entries}
        export_po_download_url={this.props.pages.export_po_download_url}
        export_csv_download_url={this.props.pages.export_csv_download_url}
        forceOpenExportDialog={this.state.forceOpenExportDialog}
        forceOpenImportDialog={this.state.forceOpenImportDialog}
        exportFunc={(options) => { this.props.exportPages(this.props.editLanguage, options) }}
        importFunc={(formData, fileType, options) => { this.props.importPages(formData, fileType, this.props.editLanguage, options) }}
        syncFunc={() => { this.props.syncPages(this.props.editLanguage)}}
        clearFunc={this.props.clearExportUrls}
        pending={this.props.pages.export_pages_pending}
        scope={'pages'}
        additionalFields={['custom_contents']} 
      />
    </React.Fragment> 
  }
}

const mapStateToProps = ({ pages, shop }) => ({
  pages: pages,
  editLanguage: shop.shop.edit_language,
  shopPlan: shop.shop.charge.name,
  shopUrl: shop.shop.url,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      syncPages,
      syncPage,
      syncPageMetafields,
      savePages,
      createPageCustomContents,
      deletePageCustomContent,
      exportPages,
      importPages,
      resetErrors,
      clearExportUrls
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(Pages))))