import React, { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    padding: 0,
    margin: 0,
    '&.MuiTabs-root': {
      minWidth: 'auto',
      minHeight: 'auto',
    },
    '& .MuiTab-root': {
      minWidth: 'auto',
      minHeight: 'auto',
      padding: '8px 8px',
    },
    '& .MuiTab-fullWidth': {
      flexBasis: 'auto',
    }
  },
  container: {
    padding: '0 16px',
    margin: 0,
  },
  textField: {
    margin: '8px 0',
  }
}));

const defaultTexts = {
  recommendation: 'Looks like your browser is set to [language]. Change the language?',
  button: 'Change',
  language: 'English',
}

export default function TextObjectTranslator(props) {
  const classes = useStyles();
  const [currentLanguage, setCurrentLanguage] = useState(props.languages[0].code);
  const [data, setData] = useState(props.data ? props.data : {});
  const [currentFields, setCurrentFields] = useState({});

  useEffect(() => {
    setCurrentLanguage(props.languages[0].code);
    handleChangeTab(null, props.languages[0].code);
    return () => {}
  }, [])

  const handleChangeTab = (event, newLanguage) => {
    let newFields = {};

    for(let i=0; i<props.fields.length; i++) {
      let field = props.fields[i];
      newFields[field] = '';

      if(data[newLanguage] && data[newLanguage][field]) {
        newFields[field] = data[newLanguage][field];
      }
    }
    setCurrentLanguage(newLanguage);
    setCurrentFields(newFields);
  };

  const handleChangeField = (event, field) => {
    let newData = JSON.parse(JSON.stringify(data));
    if(!newData[currentLanguage]) {
      newData[currentLanguage] = {};
    }
    props.languages.map((item, index) => {
      if(item.code === currentLanguage) {
        newData[currentLanguage][field] = event.target.value;
      }
    });    
    setData(newData);
    setCurrentFields(JSON.parse(JSON.stringify(newData[currentLanguage])));

    props.onChange(newData);
  };

  return (
    <React.Fragment>
      <Tabs
        className={classes.root}
        value={currentLanguage}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        variant={(props.languages.length <= 6) ? 'fullWidth' : 'scrollable'}
        aria-label="scrollable auto tabs"
        //centered={(props.languages.length <= 6)}
        scrollButtons={'auto'}
      >
        {props.languages.map((item, index) => {
          return <Tab key={`text-lang-tab-${item.code}`} label={item.code} value={item.code} />
        })}
      </Tabs>
      <Divider style={{margin: '0 0 16px 0', padding: 0}} />
      <div className={classes.container}>
        {props.fields.map((field, index) => {
          const langName = props.languages.find(x => x.code == currentLanguage) ? props.languages.find(x => x.code == currentLanguage).name : '';
          return <TextField
            className={classes.textField}
            value={currentFields[field]}
            onChange={(e) => handleChangeField(e, field)}
            label={field}
            id="filled-size-small"
            placeholder={props.defaultTexts[field].replace('[language]', langName)}
            size="small"
            fullWidth
            multiline
            maxRows={4}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />        
        })}
      </div>
    </React.Fragment>
  );
}